<template>
  <div>
    <ArrowButton
      v-if="store.isIncognito"
      :to="'/login'"
    >{{ hasToken ? 'Start' : 'Log ind' }}
    </ArrowButton>
    <div v-else>
      <h1>Aftaler</h1>
      <AgreementList />
    </div>
    <div class="mt-2" v-if="features.Chat && !store.isIncognito">
      <ChatDialog />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ArrowButton, ChatDialog, hasJwtToken, useFeatures } from '@dims/components';
import AgreementList from './AgreementList.vue';
import { useStore } from '@/store/store';

const store = useStore();
const features = useFeatures();

const hasToken = computed(() => hasJwtToken());

</script>
